.users_page,
.subscriptions_page,
.banned_extension-page {
  width: 80%;
  padding: 0 20px;
}
.users_statistic {
  width: 100%;
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
}

.users_statistic
  > :where(.css-dev-only-do-not-override-xu9wm8).ant-card
  .ant-card-body {
  padding: 15px 20px;
}

.users_statistic .ant-card {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.users_page .ant-table-container > div.ant-table-tbody {
  max-height: 50px !important;
}

@media screen and (max-width: 768px) {
  .users_page,
  .subscriptions_page,
  .banned_extension-page {
    width: 100%;
    padding: 0;
  }
}

.css-3w2yfm-ValueContainer {
  max-height: 200px !important;
  overflow-y: auto !important;
}

.actions {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
}

.actions button:not([role="switch"]) {
  background: none;
  border-radius: 5px;
  height: auto;
}
