.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.users_statistic {
  display: flex;
  margin-bottom: 5px;
}

.users_statistic .ant-card {
  margin: 0 5px;
}

.subscriptions_edit_form input,
.subscriptions_edit_form span {
  margin-bottom: 5px;
}

.panel-container {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
}

.panel-container .side-bar {
  margin-right: 20;
  width: 20%;
  position: relative;
}

.mobile-menu {
  display: none;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  position: fixed;
  margin: 0 auto;
  bottom: 10px;
  z-index: 100;
  border-radius: 10px;
  flex-direction: row;
  flex-wrap: nowrap;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
}
.mobile-menu .mobile-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  margin: 7px;
  border-radius: 10px;
  position: relative;
}

.mobile-menu .mobile-menu-item:active,
.mobile-menu .mobile-menu-item:focus,
.mobile-menu .mobile-menu-item:focus-visible {
  background: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.mobile-menu .mobile-menu-item:nth-child(1)::after {
  content: "";
  background-color: rgb(202, 201, 201);
  color: #1f1e1e;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: translateX(var(--translateX, 0));
  border-radius: 10px;
  opacity: 0.3;
  transition: transform 0.3s ease;
}

.mobile-menu .mobile-menu-item .menu-icon {
  font-size: 22px;
  pointer-events: none;
}

.mobile-menu .mobile-menu-item .menu-name {
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .side-bar {
    display: none;
  }
  .mobile-menu {
    display: flex;
  }
}
