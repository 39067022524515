span.ant-typography {
  display: block !important;
  margin-top: 10px;
}

*[required] {
  position: relative;
}

*[required]::after {
  content: "*";
  color: red;
  font-weight: 600;
  font-size: 18;
}
