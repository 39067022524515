.text_input_container {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.text_input_container label {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 74);
  user-select: none;
}

.text_input_container label.label-error {
  color: #ff0000;
}

.text_input_container div {
  background: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 6px;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text_input_container div input {
  background: none;
  border: none;
  color: rgba(0, 0, 0, 0.74);
  font-size: 16px;
  font-weight: 500;
  outline: none;
  height: 100%;
  flex: 1;
  padding-left: 10px;
}

.text_input_container div.input-status-primary:focus-within {
  border-color: rgb(59, 181, 154);
}
.text_input_container div.input-status-secondary:focus-within {
  border-color: rgb(22, 119, 255);
}
.text_input_container div.input-error:focus-within {
  border-color: rgba(255, 0, 0, 1);
}

.text_input_container div.input-error {
  border-color: rgba(255, 0, 0, 0.4);
}
.text_input_container div.input-error:hover {
  border-color: rgba(255, 0, 0, 1);
}

.text_input_container input::placeholder {
  color: rgb(128, 128, 128);
}

.text_input_container div.input-error input::placeholder {
  color: rgb(255, 42, 42);
}
